import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import React from 'react';
import styled from 'styled-components';
import Media from '../Media/Media';
import Img from 'gatsby-image';
import typography from '../../typography';

const Hero = styled.section`
  padding-top: ${props => props.theme.spacing.xl};
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  background-color: ${props => props.theme.colors['light-grey']};

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    padding-top: ${props => props.theme.spacing.xxl};
  }
`;

const HeroImage = styled.div`
  width: 100%;
  position: relative;

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    margin-left: 17.5rem;
    width: calc(100% - 17.5rem);
  }

  img {
    width: 100%;
  }
`;

const ImageTitleWrapper = styled.div`
  position: absolute;
  top: 30%;
  text-align: center;
  width: 100%;
`;

const ImageTitle = styled.h1`
  color: white;
  font-size: 200%;
  @media (min-width: ${props => props.theme.breakpoints.l}) {
    font-size: 80px;
  }
`;

const ImageSubtitle = styled.h2`
  color: white;
  font-size: 150%;
  @media (min-width: ${props => props.theme.breakpoints.l}) {
    font-size: 40px;
  }
`;

const ScrollImage = styled.img`
  width: 40px !important;
  height: auto;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 2rem;
  animation-name: Bounce;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 5;
  animation-direction: alternate;
  animation-fill-mode: forwards;
  display: none;
  @media (min-width: ${props => props.theme.breakpoints.l}) {
    display: block;
    cursor: pointer;
  }

  @keyframes Bounce {
    0% {
      transform: translate(-50%, -50%);
    }
    100% {
      transform: translate(-50%, 0%);
    }
  }
`;

const getOptions = locale => ({
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      const { target } = node.data;

      const { description, file } = target.fields;
      return (
        <Media
          alt={description}
          mediaItem={{
            file: {
              contentType: file[locale].contentType,
              url: file[locale].url,
            },
          }}
        />
      );
    },
  },
});

const Wrapper = styled.div`
  max-width: ${props => props.theme.breakpoints.m};
  margin: 2em auto;
  padding: 0 ${props => props.theme.spacing.l};

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    padding: 0 ${props => props.theme.spacing.l} 0
      ${props => props.theme.spacing.xxl};
    transform: translateX(calc(17.5rem / 2));
    padding: 0;
  }
`;

const ParagraphWrapper = styled.div`
  background-color: ${props => props.theme.colors.brand};
  padding: ${props => props.theme.spacing.s};
  margin-top: -3px;

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    padding: 5rem;
  }

  p,
  h3,
  h4,
  h5,
  h6 {
    color: ${props => props.theme.colors.white};
    /* text-align: center; */
  }
`;

const Tag = styled.h2`
  ${typography.h2_subtitle};
  /* text-align: center; */
`;

const Header = styled.h2`
  ${typography.h2};
  color: ${props => props.theme.colors.white};
  /* text-align: center; */
`;

export default function Banner(props) {
  const {
    title,
    tag,
    image,
    paragraph,
    node_locale,
    imageTitle,
    imageSubtitle,
  } = props;

  return (
    <Hero>
      <HeroImage>
        <Img key={image.id} fluid={image.fluid} />
        <ImageTitleWrapper>
          {imageTitle && <ImageTitle>{imageTitle}</ImageTitle>}
          {imageSubtitle && <ImageSubtitle>{imageSubtitle}</ImageSubtitle>}
        </ImageTitleWrapper>
        <ScrollImage
          onClick={e => {
            window.scrollTo(0, e.clientY);
          }}
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MCA3Ni4yNDMiPg0KICA8ZGVmcz4NCiAgICA8c3R5bGU+DQogICAgICAuY2xzLTEgew0KICAgICAgICBmaWxsOiAjZmZmOw0KICAgICAgfQ0KICAgIDwvc3R5bGU+DQogIDwvZGVmcz4NCiAgPHBhdGggaWQ9IlVuaW9uXzIiIGRhdGEtbmFtZT0iVW5pb24gMiIgY2xhc3M9ImNscy0xIiBkPSJNMTkuODIyLDc2LjIzNWwtLjAwNy4wMDdMMTguNCw3NC44MjlsLjAwNy0uMDA3LTUuNjUtNS42NSwxLjQxNC0xLjQxNCw1LjY1LDUuNjUsNS42NDktNS42NSwxLjQxNSwxLjQxNC01LjY1LDUuNjUuMDA3LjAwNy0xLjQxNCwxLjQxNFpNMTIuMjE1LDYzLjQyOEExOS45ODIsMTkuOTgyLDAsMCwxLDAsNDVWMjBhMjAuMDA2LDIwLjAwNiwwLDAsMSwzOC40MjgtNy43ODVBMTkuODg5LDE5Ljg4OSwwLDAsMSw0MCwyMFY0NUEyMC4wMTIsMjAuMDEyLDAsMCwxLDEyLjIxNSw2My40MjhabS4zOS02MC45YTE4LjksMTguOSwwLDAsMC02LjA0LDQuMTU0LDE5LjM1LDE5LjM1LDAsMCwwLTQuMDcyLDYuMTYxQTE5LjYsMTkuNiwwLDAsMCwxLDIwLjM4NVY0NC42MTZhMTkuNiwxOS42LDAsMCwwLDEuNDkzLDcuNTQ2LDE5LjM2LDE5LjM2LDAsMCwwLDQuMDcyLDYuMTYxLDE4LjkxLDE4LjkxLDAsMCwwLDYuMDQsNC4xNTMsMTguNzExLDE4LjcxMSwwLDAsMCwxNC43OTEsMCwxOC45MDYsMTguOTA2LDAsMCwwLDYuMDM5LTQuMTUzLDE5LjM0NSwxOS4zNDUsMCwwLDAsNC4wNzItNi4xNjFBMTkuNiwxOS42LDAsMCwwLDM5LDQ0LjYxNlYyMC4zODVhMTkuNiwxOS42LDAsMCwwLTEuNDkzLTcuNTQ2LDE5LjMzNSwxOS4zMzUsMCwwLDAtNC4wNzItNi4xNjFBMTguOSwxOC45LDAsMCwwLDI3LjQsMi41MjRhMTguNzExLDE4LjcxMSwwLDAsMC0xNC43OTEsMFpNMTksMzJWMTVoMlYzMloiLz4NCjwvc3ZnPg0K"
        ></ScrollImage>
      </HeroImage>

      {paragraph ? (
        <ParagraphWrapper>
          <Wrapper>
            {tag ? <Tag>{tag}</Tag> : ''}
            {title ? <Header h={2}>{title}</Header> : ''}

            {documentToReactComponents(paragraph.json, getOptions(node_locale))}
          </Wrapper>
        </ParagraphWrapper>
      ) : (
        ''
      )}
    </Hero>
  );
}
