import React, { Component } from 'react';
import styled from 'styled-components';

const Fader = styled.div`
  padding-top: ${props => props.theme.spacing.xl};
  transition: all .3s ease-in-out;
  transition-delay: .3s;
  opacity: ${props => (props.fadedIn ? 1 : 0)};
  transform: translateY(${props => (props.fadedIn ? 0 : '50%')});

  @media (min-width: ${props => props.theme.breakpoints.l}){
    padding-top: ${props => props.theme.spacing.xxl};
  }
`;

export default class FadeIn extends Component {
  state = {
    fadedIn: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.fadedIn === false &&
      this.props.componentsInView.includes(this.props.componentId)
    ) {
      this.setState({
        fadedIn: true,
      });
    }
  }

  render() {
    return <Fader fadedIn={this.state.fadedIn}>{this.props.children}</Fader>;
  }
}
