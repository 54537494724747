import React from 'react';
import styled from 'styled-components';
import Container from '../Container';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledImage = styled.img`
  // width: 100%;
  // height: auto;
`;

const StyledVideo = styled.video`
  width: calc(100% - 2rem);
  max-width: 768px;
  margin: 0 auto;
  height: auto;
  @media (min-width: ${props => props.theme.breakpoints.l}) {
    width: calc(100% - 6rem);
  }
`;

export default function Media(props) {
  let contentType = '';
  let url = '';
  if (props.mediaItem && props.mediaItem.file) {
    contentType = props.mediaItem.file.contentType;
    url = props.mediaItem.file.url;
  }

  const remainingProps = {};
  Object.assign(remainingProps, props);
  delete remainingProps.mediaItem;

  // map any specified attributes in Contentful to html attributes (by default initialized to true)
  // this makes it possible to specify autoplay, muted, etc
  const { attributes } = props;
  let attrObject = {};
  if (attributes) {
    attrObject = attributes.reduce((obj, key) => ({ ...obj, [key]: true }), {});
  }

  if (contentType.includes('image')) {
    return <StyledImage src={url} {...remainingProps} />;
  }
  return (
    <Container>
      <Wrapper>
        <StyledVideo src={url} {...attrObject} {...remainingProps} />
      </Wrapper>
    </Container>
  );
}
