import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  /* max-width: 1560px; */
  max-width: 1280px;
  margin: 2.5rem auto;

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    margin: 5.5rem auto;
    padding-left: 17.5rem;
  }
`;

export default function Container(props) {
  return <Wrapper>{props.children}</Wrapper>;
}
