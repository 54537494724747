import React, { Component } from 'react';
import Helmet from 'react-helmet';

import androidChrome192 from '../images/icons/android-chrome-192x192.png';
import androidChrome512 from '../images/icons/android-chrome-512x512.png';
import appleTouch from '../images/icons/apple-touch-icon.png';
import favicon16 from '../images/icons/favicon-16x16.png';
import favicon32 from '../images/icons/favicon-32x32.png';
import favicon from '../images/icons/favicon.ico';
import pinnedTab from '../images/icons/safari-pinned-tab.svg';

// <link rel="manifest" href="../images/icons/site.webmanifest" />
// <meta name="msapplication-config" content="../images/icons/browserconfig.xml" />

export default class Favicon extends Component {

  render() {
    return (
      <Helmet>
          <link rel="apple-touch-icon" sizes="180x180" href={appleTouch} />
          <link rel="icon" type="image/png" sizes="512x512" href={androidChrome512} />
          <link rel="icon" type="image/png" sizes="192x192" href={androidChrome192} />
          <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
          <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
          <link rel="mask-icon" href={pinnedTab} color="#752a85" />
          <link rel="shortcut icon" href={favicon} />
          <meta name="msapplication-TileColor" content="#752a85" />
          <meta name="theme-color" content="#752a85" />
        </Helmet>
    );
  }

}
