import loadable from '@loadable/component';
import React from 'react';
import { mapTypeNameToComponentName } from '../../utils';
import logo from '../../images/25-jaar.svg';
import styled from 'styled-components';

const AsyncComponent = loadable(props =>
  import(`../${props.name}/${props.name}`)
);

const Nav = styled.nav`
  position: fixed;
  top: 56px;
  left: 0;
  width: 17.5rem;
  background-color: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spacing.m} ${props => props.theme.spacing.l};
  transform: ${props => (props.active ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.35s ease-in-out;
  max-height: calc(100vh - 56px);
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  z-index: 8;

  ul {
    list-style-type: none;
  }

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    top: 72px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xl}) {
    transform: translateX(0);
    transition: transform 0.35s ease-in-out;
    z-index: 2;
    animation-duration: .5s;
    animation-timing-function: ease-out;
    animation-delay: .5s;
    animation-iteration-count: 1;
    animation-name: appear;
  }

  @keyframes appear {
    0% {
      transform: translateX(-100%);
    }

    99.9% {
      transform: translateX(0);
    }
  }
`;

const LustrumList = styled.ul`
  border-left: 3px solid ${props => props.theme.colors.brand};
  padding-left: ${props => props.theme.spacing.m};
  padding-top: ${props => props.theme.spacing.m};
  position: relative;
  margin: 0;
`;

const Logo = styled.img`
  padding: ${props => props.theme.spacing.m} 0 ${props => props.theme.spacing.l};
  margin: 0 auto;
  width: calc(80% + ${props => props.theme.spacing.xxl});
  max-width: 102px;
  display: block;
`;

const Divider = styled.hr`
  border: none;
  border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
  width: calc(100% + ${props => props.theme.spacing.xxl});
  margin: 0 0 0 -${props => props.theme.spacing.l};
`;

/**
 * Loops through the tree of tineline items and finds the first item that matches any of the components on the page
 */
const pickActiveComponent = (componentsInView, timelineLustrums) => {
  let match = null;
  timelineLustrums.forEach(lustrum => {
    if (!match && lustrum.timelineItems) {
      match = lustrum.timelineItems.reduce((accumulator, timelineItem) => {
        if (!accumulator) {
          let componentMatch = null;
          componentsInView.forEach(component => {
            if (
              timelineItem.linkedComponent.length &&
              timelineItem.linkedComponent[0].id === component
            ) {
              componentMatch = component;
            }
          });
          return componentMatch;
        }
        return accumulator;
      }, null);
    }
    // else if (!lustrum.timelineItems) {
    //   console.log('something went wrong in the timeline active component picking 🥒 ⛏️');
    // }
  });
  return match;
};

const TimelineNavigation = props => {
  const { timelineLustrums } = props;
  const components = timelineLustrums
    ? timelineLustrums.map(item => {
        const { __typename, id } = item;
        return {
          name: mapTypeNameToComponentName(__typename),
          forwardedProps: item,
          id,
        };
      })
    : [];

  return (
    <Nav active={props.isMenuToggled}>
      <Logo src={logo} alt="Iquality 25 jaar" />
      <Divider />
      <LustrumList>
        {components.map(component => (
          <AsyncComponent
            activeComponent={pickActiveComponent(
              props.componentsInView,
              timelineLustrums
            )}
            toggleMenu={props.toggleMenu}
            key={component.id}
            name={component.name}
            {...component.forwardedProps}
          />
        ))}
      </LustrumList>
    </Nav>
  );
};

export default TimelineNavigation;
