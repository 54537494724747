import React from 'react';
import loadable from '@loadable/component';
import { mapTypeNameToComponentName } from '../utils';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import Favicon from '../components/favicon';
import FadeIn from '../components/FadeIn';
import TimeLineNavigation from '../components/TimelineNavigation/TimelineNavigation';
import { graphql } from 'gatsby';
import { InView } from 'react-intersection-observer';
import Helmet from 'react-helmet';

const AsyncComponent = loadable(props =>
  import(`../components/${props.name}/${props.name}`)
);

const componentsInitiallyInView = new Set();
const componentsFalselyInView = new Set();

const getFirstTimelineItem = props => {
  let value;
  try {
    value =
      props.data.contentfulPage.timeline.timelineLustrums[0].timelineItems[0]
        .linkedComponent[0].id;
  } catch (error) {
    value = '';
  }
  return value;
};
class Page extends React.Component {
  state = {
    components: [],
    componentsInView: [getFirstTimelineItem(this.props)],
    userHasScrolled: false,
    isMenuToggled: false,
    shouldCheckForInitialInView: true,
    inViewHasBeenFalse: false,
  };

  componentDidMount() {
    const { data } = this.props;
    const { contentfulPage } = data;
    const { content } = contentfulPage;
    const components = content.map(item => {
      const { __typename, id } = item;
      return {
        name: mapTypeNameToComponentName(__typename),
        id,
        forwardedProps: item,
      };
    });
    this.setState({
      components,
    });
  }

  determineComponentsInitiallyInView = () => {
    const componentsInView = [];
    componentsInitiallyInView.forEach(item => {
      if (!componentsFalselyInView.has(item)) {
        componentsInView.push(item);
      }
    });
    this.setState({
      componentsInView,
      shouldCheckForInitialInView: false,
    });
  };

  onIntersectionChange = (inView, componentId, ref) => {
    if (inView) {
      componentsInitiallyInView.add(componentId);
    } else {
      componentsFalselyInView.add(componentId);
      if (!this.state.inViewHasBeenFalse) {
        this.setState({ inViewHasBeenFalse: true });
      }
    }

    if (
      componentId ===
        this.state.components[this.state.components.length - 1].id &&
      this.state.shouldCheckForInitialInView &&
      this.state.inViewHasBeenFalse
    ) {
      this.determineComponentsInitiallyInView();
    }

    const { componentsInView } = this.state;
    let newComponentsInView = componentsInView;
    if (ref.boundingClientRect.height === 0) {
      return false;
    }
    if (inView) {
      if (componentsInView.indexOf(componentId) === -1) {
        newComponentsInView.push(componentId);
      }
    } else {
      newComponentsInView = newComponentsInView.filter(
        item => item !== componentId
      );
    }

    if (componentsInView.length === 0 && newComponentsInView.length === 0) {
      return false;
    }
    this.setState({
      componentsInView: newComponentsInView,
    });
  };

  toggleMenu = () => {
    this.setState({
      isMenuToggled: !this.state.isMenuToggled,
    });
  };

  render() {
    const { data } = this.props;
    const { contentfulPage } = data;
    const {
      title,
      template,
      description,
      timeline,
      hero,
      url,
      ogImage,
      keywords = '',
    } = contentfulPage;
    const { components, componentsInView, isMenuToggled } = this.state;
    return (
      <Layout template={template}>
        <SEO
          title={title}
          description={description}
          keywords={keywords ? keywords.split(',') : ''}
          ogImage={ogImage && ogImage.file.url}
          url={url && url}
        />
        <Helmet>
          <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />
          <meta name="title" content={title} />
          <meta name="description" content={description} />
          <body className={isMenuToggled ? 'menu-open' : ''}></body>
        </Helmet>
        <Favicon />
        <Header toggleMenu={this.toggleMenu} isMenuToggled={isMenuToggled} />
        <div id="navigation">
          <TimeLineNavigation
            {...timeline}
            componentsInView={componentsInView}
            isMenuToggled={isMenuToggled}
            toggleMenu={this.toggleMenu}
          />
        </div>
        {hero ? <Hero {...hero} /> : null}
        <div id="main">
          {components.map(component => (
            <InView
              as="div"
              id={component.id}
              key={component.id}
              rootMargin={'100px'}
              threshold={0.2}
              onChange={(inView, ref) =>
                this.onIntersectionChange(inView, component.id, ref)
              }
            >
              <FadeIn
                componentsInView={componentsInView}
                componentId={component.id}
              >
                <AsyncComponent
                  name={component.name}
                  componentsInView={componentsInView}
                  componentId={component.id}
                  {...component.forwardedProps}
                />
              </FadeIn>
            </InView>
          ))}
        </div>
      </Layout>
    );
  }
}

export default Page;

export const query = graphql`
  query PageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...PageFragment
    }
  }
`;
